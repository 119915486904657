<template>
  <div class="cointer">
    <div>
      <div class="navleft">
        江苏省工程建设企业技术标准认证公告系统
        <div v-if="$store.state.role[0] == 1" style="margin-left: 15px; font-size: 14px; margin-top: 9px">
          管理版
        </div>
        <div v-if="$store.state.role[0] == 2" style="margin-left: 15px; font-size: 14px; margin-top: 9px">
          专家版
        </div>
        <div v-if="$store.state.role[0] == 3" style="margin-left: 15px; font-size: 14px; margin-top: 9px">
          企业版
        </div>
      </div>
    </div>
    <div class="navright">


      <el-dropdown>
        <div class="el-dropdown-link tohver">
          <div>
            <img v-if="
              $store.state.user.avatar != '' &&
              $store.state.user.avatar != null
            " :src="$store.state.user.avatar" alt="" width="40px" height="40px" style="border-radius: 50%" />
            <img v-else src="https://api.gbservice.cn/storage/uploads/699ab4d283acc595369836beb8082607.png" alt=""
              width="40px" height="40px" style="border-radius: 50%" />
          </div>
          <div style="
              font-size: 18px;
              font-weight: 400;
              color: #f4f5f7;
              margin-top: 9px;
              margin-left: 10px;
              cursor: pointer;
            ">
            {{ $store.state.user.name }}
          </div>
        </div>

        <el-dropdown-menu slot="dropdown" style="padding: 10px 20px">
          <div @click="loginOut()" style="
              display: flex;
              flex-direction: row-reverse;
              color: #3082e2;
              font-size: 14px;
              cursor: pointer;
            ">
            <div class="logoutbtn">退出登录</div>
          </div>
          <div style="
              display: flex;
              justify-content: space-between;
              width: 200px;
              magrin-top: 10px;
              border-bottom: 2px solid #e4e4e4;
              padding-bottom: 15px;
            ">
            <div>
              <img v-if="
                $store.state.user.avatar != '' &&
                $store.state.user.avatar != null
              " :src="$store.state.user.avatar" alt="" width="60px" height="60px" style="border-radius: 50%" />
              <img v-else src="https://api.gbservice.cn/storage/uploads/67f630cedc4a08d8cc1e4dcdc0958d7e.png" alt=""
                width="60px" height="60px" style="border-radius: 50%" />
            </div>
            <div style="width: 61%">
              <div style="font-size: 16px; font-weight: 400">
                {{ $store.state.user.name }}
              </div>
              <div style="font-size: 14px; margin-top: 5px">
                账号：{{ $store.state.user.username }}
              </div>
              <div style="font-size: 14px">
                企业：{{ $store.state.user.company }}
                <!-- <el-button @click="fillinfor">补充企业信息</el-button> -->
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 10px">
            <div style="
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
              ">
              <div @click="bofang()">
                <img src="https://api.gbservice.cn/storage/uploads/58642fff46b302ca34bfe137724d588c.png" alt=""
                  width="28px" />
              </div>
              <div style="font-size: 14px" class="gren" @click="bofang()">
                个人资料
              </div>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      
      <el-dialog title="请补充企业信息" :visible.sync="fillCompanyinformationdisplay" :before-close="handleClose">
        <el-form :model="form">
          <el-form-item label="企业名称" :label-width="formLabelWidth" :label-position="labelPosition" prop="name"
            :rules="rules.name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="所在城市" prop="region" :label-width="formLabelWidth" :label-position="labelPosition">
            <el-cascader v-model="form.city" :options="options" @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" :label-width="formLabelWidth" :label-position="labelPosition">
            <el-input v-model="form.address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮编" :label-width="formLabelWidth" :label-position="labelPosition">
            <el-input v-model="form.postcode" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="法人代表" :label-width="formLabelWidth" :label-position="labelPosition">
            <el-input v-model="form.legal_representative" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" :label-width="formLabelWidth" :label-position="labelPosition">
            <el-input v-model="form.telephone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱" :label-width="formLabelWidth" :label-position="labelPosition">
            <el-input v-model="form.email" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="企业简介" :label-width="formLabelWidth" :label-position="labelPosition">
            <el-input v-model="form.introduction" autocomplete="off" type="textarea"></el-input>
          </el-form-item>
          <!-- <el-form-item label="文件上传" :label-width="formLabelWidth" :label-position="labelPosition">
            <el-upload class="upload-demo" drag action="api/public/bupload">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将营业执照文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png/pdf文件，且不超过2Mb</div>
            </el-upload>
          </el-form-item> -->

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="fillCompanyinformationdisplay = false">取 消</el-button>
          <el-button type="primary" @click="addcompanyinformation">确 定</el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>
<script>
import { userInfoApi } from "@api/project";
import { getCities } from '@api/user';
import { organizationsStore } from '@api/enterprise';

export default {
  computed: {
    // 获取当前路由的子路由
    mean_list() {
      var routes = {
        children: this.$router.options.routes,
      };
      var route = this.$route.matched;
      for (var i = 0; i < route.length - 1; i++) {
        routes = routes.children.find((e) => e.name == route[i].name);
      }
      routes.children = routes.children
        .map((item) => {
          if (!item.meta.hidden) {
            return item;
          }
        })
        .filter((d) => d);
      return routes.children;
    },
    navleft_active() {
      return this.$route.meta.active;
    },
  },
  data() {
    return {
      options: [],
      rules: {
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ]

      },
      form: [],
      fillCompanyinformationdisplay: false,
      messageList: [],
      labelPosition: "left",
      formLabelWidth: "120px",
      user_id: 0,
      token: ""
    };
  },
  mounted() {
    this.$store.watch(
      (state) => state.messageList,
      (val) => {
        if (this) {
          this.messageList = val;
        }
      }
    );

    
    if(this.$store.state.role[0]==4){
      this.GetUserCompany();
      this.getCitiesa();
    }   
    this.token = this.$store.state.token;
    this.user_id = this.$store.state.user.id; 
    
  },
  methods: {
    // 获取所有城市数据
    getCitiesa() {
      let params = {
        token: this.$store.state.token
      }
      getCities(params).then((res) => {
        this.options = res.data;  
      });
    },
    // 添加城市信息
    handleChange(value) {
      // console.log(value);
      // 这里添加城市1、城市2
    },
    // 添加企业信息
    addcompanyinformation() {
      let params = {
        token: this.token,
        name: this.form.name,
        short_name: this.form.name,
        city1_id: this.form.city[0],
        city2_id: this.form.city[1],
        address: this.form.address,
        postcode: this.form.postcode,
        legal_representative: this.form.legal_representative,
        telephone: this.form.telephone,
        email: this.form.email,
        introduction: this.form.introduction,
        user_id: this.user_id
      }
      organizationsStore(params).then((res) => {

        if (res.code == 200) {
          this.$message({
            message: res.message,
            type: 'success'
          });
          this.fillCompanyinformationdisplay = false;
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }

      });
    },
    // 显示
    fillinfor() {
      this.fillCompanyinformationdisplay = true;
    },
    // 获取用户企业信息
    GetUserCompany() {
      let params = {
        token: this.$store.state.token,
      };
      userInfoApi(params).then((res) => {

        let organizations = res.data.organizations;
        this.form.firstProContact = res.data.username;
        this.form.firstProContactTel = res.data.telephone;
        this.form.firstProContactEmail = res.data.email;

        if (organizations != null) {
          this.form.firstUnit = res.data.organizations.name;
        } else {
          this.$message({
            type: "warming",
            message: "未补充企业信息!",
          });     

          this.fillCompanyinformationdisplay = true;
        }
      });
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    getRole() {
      return this.$store.state.user.type;
    },

    bofang() {
      if (this.$store.state.user.type == 1) {
        this.$router.push({
          path: "/user/enchildmanage",
        });
      } else if (this.$store.state.user.type == 3) {
        // console.log("3333");
        this.$router.push({
          path: "/user_user/enchildmanage",
        });
      }
    },
    loginOut() {
      this.$store.commit("login_out");
    },

  },
};
</script>
<style scoped>
.linkto {
  text-decoration: none;
  color: #3082e2;
  height: 40px;
  line-height: 40px;
}

.linkto>span:hover {
  color: #84b5ec;
}

.toling {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.logoutbtn:hover {
  color: #479dfd;
}

.gren:hover {
  color: #3082e2;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.cointer {
  width: 100%;
  height: 60px;
  background-color: #164C90;
  box-shadow: 0px 4px 4px 0px rgba(169, 169, 169, 0.25);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navleft {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #f4f5f7;
}

.navright {
  display: flex;
  flex-direction: row;
  margin-right: 26px;
}

.navleft .nlfir {
  width: 100px;
  text-align: center;
  /* border-bottom: 4px solid #479DFD; */
  padding: 18px 0;
  margin-left: 22px;
  margin-right: 58px;
  cursor: pointer;
}

.navleft .nlfir_active {
  width: 100px;
  text-align: center;
  border-bottom: 4px solid #479dfd;
  padding: 18px 0;
  margin-left: 22px;
  margin-right: 58px;
  cursor: pointer;
}

.navleft .nlsec {
  width: 70px;
  text-align: center;
  /* border-bottom: 4px solid #479DFD; */
  padding: 18px 0;
  margin-left: 22px;
  cursor: pointer;
}

.navleft .nlsec_active {
  width: 70px;
  text-align: center;
  border-bottom: 4px solid #479dfd;
  padding: 18px 0;
  margin-left: 22px;
  cursor: pointer;
}

.navright .navcen {
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #f4f5f7;
  margin-right: 20px;
}

.navright .navsec {
  color: #9c9fa3;
  margin-right: 38px;
  /* font-weight: bold; */
  cursor: pointer;
}

.borderstyle {
  border: 1px solid;
}
</style>
